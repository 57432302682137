html {
  font-size: 16px;
}

.App {
  text-align: left;
  font-family: "Lexend", san-serif;
  background-color: #0a071b;
  color: white;
}

@media (max-width: 767px) {
  .container {
    max-width: 100%;
    padding-left: 8% !important;
    padding-right: 8% !important;
  }

  html {
    font-size: 14px;
  }

  h1 {
    font-size: 3rem !important;
    font-style: normal !important;
    font-weight: 300 !important;
    line-height: 4.25rem !important;
    letter-spacing: 0em !important;
  }

  .faq-image {
    display: none;
  }

  #faq-container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  #team-container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  #footer {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0 !important;
  }

  /* .navbar {
    padding-top: 2rem !important;
  } */
}

/* FONT STYLES –––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */
h1 {
  font-size: 3.25rem !important;
  font-style: normal !important;
  font-weight: 300 !important;
  line-height: 4.25rem !important;
  letter-spacing: 0em !important;
}

h2 {
  font-size: 2.5rem !important;
  font-style: normal !important;
  font-weight: 300 !important;
  line-height: 3rem !important;
  letter-spacing: 0em !important;
}

h3 {
  font-size: 2rem;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 2.25rem !important;
  letter-spacing: 0em !important;
}

h4 {
  font-size: 1.25rem;
  font-style: normal !important;
  font-weight: 300 !important;
  line-height: 2rem !important;
  letter-spacing: 0em !important;
}

h5 {
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 1.375rem !important;
  letter-spacing: -0.02em !important;
}

p {
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: -0.02em;
}

a {
  text-decoration: none !important;
  color: inherit !important;
}

.legal {
  font-size: 0.9rem;
  color: #c8c8c8;
}

.text-black {
  color: black;
}

.text-blue {
  color: #a0ccff;
}

.text-red {
  color: #ff7e4e;
}

.text-pink {
  color: #ff9c9c;
}

.text-white {
  color: white;
}

.text-white-secondary {
  color: #ccd1d7;
}

.text-white-muted {
  color: #a5a5a5;
}

.text-align-center {
  text-align: center !important;
}

.text-align-right {
  text-align: right !important;
}

.text-inherit {
  font-size: inherit !important;
}

.float-right {
  float: right;
}

.text-20 {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.kerning-2 {
  letter-spacing: -2%;
}

.App-link {
  color: #61dafb;
}

/* BACKGROUND STYLES –––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */
.background-transparent {
  background-color: transparent !important;
}

.circle-fill {
  border-radius: 50%;
  width: 58px;
  height: 58px;
  padding: 20px;
  background: #fff;
  text-align: center;
}

.circle-stroke {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 8px;
  border: 2px solid #fff;
  text-align: center;
}

.emojiCircle {
  display: inline-block;
  line-height: 0px;
  border-radius: 50%;
  font-size: 1.5rem;
}

.emojiCircle span {
  display: inline-block;
  padding-top: 50%;
  padding-bottom: 50%;
  margin-left: 24px;
  margin-right: 24px;
}

.numberCircle {
  display: inline-block;
  line-height: 0px;
  border-radius: 50%;
  font-size: 1.5rem;
  background: white;
}

.numberCircle span {
  display: inline-block;
  padding-top: 50%;
  padding-bottom: 50%;
  margin-left: 16px;
  margin-right: 16px;
}

.background-muted-blue {
  background-color: #5f7da5;
}

.background-muted-red {
  background-color: #e37c85;
}

.background-muted-footer-blue {
  background-color: #042d46;
}

.background-muted-green {
  background-color: #324549;
}

.background-muted-orange {
  background-color: #403638;
}

.background-muted-teal {
  background-color: #303b55;
}

.background-muted-purple {
  background-color: #3c3355;
}

.background-attribute-blue {
  background-color: #3c6391;
}

.background-muted-black {
  background-color: #1a1823;
}

/* SPACING STYLES –––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */
.mt-6 {
  margin-top: 4rem !important;
}

.mb-6 {
  margin-bottom: 4rem !important;
}

.mb-7 {
  margin-bottom: 5rem !important;
}

.mb-8 {
  margin-bottom: 6rem !important;
}

.mb-9 {
  margin-bottom: 7rem !important;
}

.mb-10 {
  margin-bottom: 8rem !important;
}

.mb-section {
  margin-bottom: 10rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.mr-4 {
  margin-right: 1.5rem !important;
}

.mr-5 {
  margin-right: 3rem !important;
}

.mr-6 {
  margin-right: 4rem !important;
}

.mr-7 {
  margin-right: 5rem !important;
}

.ml-5 {
  margin-left: 3rem !important;
}

.pb-6 {
  padding-bottom: 4rem !important;
}

.pt-6 {
  padding-top: 4rem !important;
}

.ml-neg-4 {
  margin-left: -1.5rem;
}

.ml-neg-5 {
  margin-left: -3rem;
}

.mt-neg-5 {
  margin-top: -3rem;
}

.mt-neg-8 {
  margin-top: -6rem;
}

.mb-neg-6 {
  margin-bottom: -4rem;
}

.no-wrap {
  flex-shrink: 0;
}

.gx-6 {
  --bs-gutter-x: 4rem !important;
}

.gy-6 {
  --bs-gutter-y: 4rem !important;
}

/* Navbar STYLES –––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */
.navbar {
  /* margin: 0 8rem; */
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 5rem !important;
  background: transparent !important;
}

.nav-link {
  color: white !important;
  font-size: 1.25rem;
  font-weight: 500;
  padding: 0.75rem !important;
}

.navbar-toggler {
  color: white !important;
  border-color: white !important;
}

.navbar-toggler-icon {
  /* color: white !important;
  border-color: white !important; */
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(255, 255, 255, 0.55)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important;
}

/* Benefit STYLES –––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */
.benefit {
  max-width: 500px;
}

/* Attribute STYLES –––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */
.attribute-section-card {
  border-radius: 0.5rem !important;
  background-color: transparent !important;
}

.attribute-card {
  padding: 0 0 !important;
}

.card {
  border: none !important;
}

.card-subtitle {
  font-weight: 400 !important;
  font-size: 0.85rem !important;
}

.card-title {
  font-weight: 500 !important;
  font-size: 1.5rem !important;
}

.bg-common {
  background-color: #606060 !important;
}

.bg-rare {
  background-color: #d4af37 !important;
}

.bg-extremely-rare {
  background-color: #70d1f4 !important;
}

.text-common {
  color: white !important;
  background-color: #60606040 !important;
}

.text-rare {
  color: #d4af37 !important;
  background-color: #d4af3725 !important;
}

.text-extremely-rare {
  color: #70d1f4 !important;
  background-color: #70d1f425 !important;
}

.badge {
  font-size: 1.25rem !important;
  font-weight: 500 !important;
  padding: 0.75rem 1rem !important;
}

/* Roadmap STYLES –––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */
.roadmap-title {
  font-size: 1.5rem;
  font-weight: 400;
}

.roadmap-description {
  font-size: 1rem;
  font-weight: 300;
}

.roadmap {
  background-color: #141e33;
}

/* Team STYLES –––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */
.team-card {
  background-color: #0e152b;
  /* background-color: red; */
  margin-bottom: 4rem;
}

.team-member-name {
  font-size: 2rem;
}

.team-member-description {
  font-size: 1.25rem;
  font-weight: 400;
}

/* FAQ STYLES –––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */
.question {
  font-size: 1.125 rem;
  font-weight: 600;
}

.answer {
  font-size: 1.125 rem;
}

/* Footer STYLES –––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */
.footer {
  padding-bottom: 4rem !important;
}

/* .to-right-edge {
  margin-right: 0 !important;
} */
